  import React, { useState, useEffect } from 'react';
  import { Link } from 'react-router-dom';
  import trophy from "../assests/iCON/noun-trophy-5126269.png"
  import add from "../assests/iCON/noun-create-1409661.png"
  import TodayQuiz from './quiz-tabs/Today-Quiz';
  import {
    Row,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Col,
  } from "reactstrap";
  import { QuizFilter } from "./QuizFilters"
  import Header from '../../components/Header';
  import { FaSortAmountUpAlt } from "react-icons/fa"
  import { useParams } from 'react-router-dom';
  import { exportselectedCountry, exportselectedLevel, exportselectedUniversity, exportselectedLanguages, exportselectedBranch, exportselectedSubchapter, exportselectedChapter } from "./QuizFilters"
  import { quizQuestion, updateDates, BranchesFilter, ChapterFilter, SubChapterFilter, ChnageTabs, CheckedBranch } from "../../store/features/QuizSlice"
  import { useDispatch, useSelector } from "react-redux";
  import { MdOutlineFormatIndentIncrease } from "react-icons/md"
  import moment from 'moment';
  import { getDatabase, ref, update, child, get } from "firebase/database";
  import { db } from '../../components/firebase'

  const Quiz = () => {

    let localStorageObject = localStorage.getItem("Exe-Med_Student_Info");
    var data = JSON.parse(localStorageObject);

    let Past_Week_Date = localStorage.getItem("Past_Week_Date");
    var weekDate = JSON.parse(Past_Week_Date);

    let Today_Date = localStorage.getItem("Today_Date");
    var todayDate = JSON.parse(Today_Date);

    let Custom_Tab = localStorage.getItem("Custom_Tab");
    var CustomTab = JSON.parse(Custom_Tab);

    let Past_Month_Date = localStorage.getItem("Past_Month_Date");
    var monthDate = JSON.parse(Past_Month_Date);

    let localStorageObjectBranch = localStorage.getItem("Branch_List_" + data?.id);
    var BrancheFilterData = localStorageObjectBranch ? JSON.parse(localStorageObjectBranch) : [];

    let localStorageObjectChapter = localStorage.getItem("Chapter_List_" + data?.id);
    var ChapterFilterData = (localStorageObjectChapter && localStorageObjectChapter != "undefined") ? JSON.parse(localStorageObjectChapter) : [];

    let localStorageObjectSubChapter = localStorage.getItem("Subchapter_List_" + data?.id);
    var SubChapterFilterData = localStorageObjectSubChapter ? JSON.parse(localStorageObjectSubChapter) : [];

    let localStorageObjectLanguage = localStorage.getItem("Language_List_" + data?.id);
    var LanguageFilterData = JSON.parse(localStorageObjectLanguage);

    let localStorageObjectCountry = localStorage.getItem("Country_List_" + data?.id);
    var CountryFilterData = JSON.parse(localStorageObjectCountry);

    let localStorageObjectUniversity = localStorage.getItem("University_List_" + data?.id);
    var UniversityFilterData = JSON.parse(localStorageObjectUniversity);

    let localStorageObjectLevel = localStorage.getItem("Level_List_" + data?.id);
    var LevelFilterData = JSON.parse(localStorageObjectLevel);

    let localStorageObjectBookmarked = localStorage.getItem("Book_marked");
    var BookmarkedData = JSON.parse(localStorageObjectBookmarked);

    let localStorageObjectNeverAttempt = localStorage.getItem("Never_Attempt");
    var NeverAttempted = JSON.parse(localStorageObjectNeverAttempt);

    let localStorageObjectMistakes = localStorage.getItem("Mistakes");
    var LocalMistakes = JSON.parse(localStorageObjectMistakes);

    let localStorageObjectQuestionCount = localStorage.getItem("Question_Count");
    var QuestionCount = JSON.parse(localStorageObjectQuestionCount);

    let localStorageObjectStartDate = localStorage.getItem("start_date");
    var startDate = JSON.parse(localStorageObjectStartDate);

    let localStorageObjectEndDate = localStorage.getItem("end_date");
    var endDate = JSON.parse(localStorageObjectEndDate);

    let { id } = useParams()
    const dispatch = useDispatch();
    var quizfilterData = useSelector(state => state.quiz)
    const tabsBtn = useSelector(state => state.quiz.tabs)
    const isChecked = useSelector(state => state.quiz.isChecked)
    const BranchIds = useSelector(state => state.quiz.brancheFilter)
    const [hideQuiz, setHideQuiz] = useState(false)
    const chnageColor = () => {

      var element = document.getElementById("colorChange");
      element.classList.remove("btn-red");
      element.classList.add("create-posts");

      var element = document.getElementById("pastWeek");
      element.classList.remove("btn-red");

      element.classList.add("create-posts");
      var element = document.getElementById("thisWeek");
      element.classList.remove("btn-red");
      element.classList.add("create-posts");

      var custom = document.getElementById("customBtn");
      custom.classList.remove("create-posts");
      custom.classList.add("btn-red");
      setHideQuiz(true)

      const now = new Date();
      const start_date = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      const end_date = new Date(now.getFullYear() + 1, now.getMonth(), now.getDate());


      dispatch(CheckedBranch(true))

      dispatch(updateDates({
        startDate: start_date,
        endDate: end_date
      }))
      dispatch(BranchesFilter({
        startDate: null,
        endDate: null,
        customTab: 4
      }))
      dispatch(ChapterFilter({
        startDate: null,
        endDate: null,
        object: [],
        customTab: 4
      }))
      dispatch(SubChapterFilter({
        startDate: null,
        endDate: null,
        object: [],
        customTab: 4
      }))

      dispatch(quizQuestion({
        startDate: null,
        endDate: null,
        branchId: BrancheFilterData == null ? [] : BrancheFilterData,
        chapterId: ChapterFilterData == null ? [] : ChapterFilterData,
        subChapterId: SubChapterFilterData == null ? [] : SubChapterFilterData,
        // Commenting for now but change in future
        // LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
        CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
        UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
        // Commenting for now but change in future
        LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
        Bookmarked: null,
        NeverAttempted: null,
        Mistakes: null,
        questionId: id = undefined ? 0 : parseInt(id),
        questionCount: 10,
      }))

      localStorage.setItem("Custom_Tab", JSON.stringify("custom"));
      localStorage.removeItem("start_date");
      localStorage.removeItem("end_date");
      localStorage.removeItem("Today_Date", JSON.stringify(todayDate));
      localStorage.removeItem("Past_Week_Date", JSON.stringify(weekDate));
      localStorage.removeItem("Past_Month_Date", JSON.stringify(monthDate));

    }

    useEffect(() => {
      
      if (window.location.pathname == `/quiz/${id}`) {
        paramsId()
      }
      else {
        if (todayDate == null && weekDate == null && monthDate == null && CustomTab == null) {
          //  Commenting for now but change in future tabNull() function should be
          // tabNull()
          chnageColor()
        }
        if (todayDate == null && weekDate == null && CustomTab == null && monthDate != null) {
          PastWeekBtn()
        }
        if (weekDate == null && monthDate == null && CustomTab == null && todayDate != null) {
          today()
        }
        if (todayDate == null && monthDate == null && CustomTab == null && weekDate != null) {
          ThisWeekQuiz()
        }

        if (todayDate == null && monthDate == null && weekDate == null && CustomTab != null) {
          chnageColor()
        }

      }


    }, [])

    const paramsId = () => {
      dispatch(quizQuestion({
        startDate: null,
        endDate: null,
        branchId: [],
        chapterId: [],
        subChapterId: [],
        // 
        // LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
        CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
        UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
        // Commenting for now but change in future
        LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
        Bookmarked: null,
        NeverAttempted: null,
        Mistakes: null,
        questionId: id = undefined ? 0 : parseInt(id),
        questionCount: 10,
      }))
    }
    const tabNull = () => {
      var element = document.getElementById("colorChange");
      element.classList.add("btn-red");
      element.classList.remove("create-posts");
      dispatch(quizQuestion({
        startDate: (moment(new Date()).format('YYYY-MM-DD')),
        endDate: (moment(new Date()).format('YYYY-MM-DD')),
        branchId: [],
        chapterId: [],
        subChapterId: [],
        // Commenting for now but change in future
        // LanguageId: LanguageFilterData == null ? [data.languageId] : LanguageFilterData,
        CountryId: CountryFilterData == null ? [data.countryId] : CountryFilterData,
        UniversityId: UniversityFilterData == null ? [data.universityId] : UniversityFilterData,
        // Commenting for now but change in future
        LevelId: LevelFilterData == null ? [data.userLevelId] : LevelFilterData,
        Bookmarked: null,
        NeverAttempted: null,
        Mistakes: null,
        questionCount: 10,
      }))
      dispatch(updateDates({
        startDate: new Date(),
        endDate: new Date(),
      }))
    }
    const today = () => {
      dispatch(quizQuestion({
        startDate: (moment(new Date()).format('YYYY-MM-DD')),
        endDate: (moment(new Date()).format('YYYY-MM-DD')),
        branchId: [],
        chapterId: [],
        subChapterId: [],
        // Commenting for now but change in future
        // LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
        CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
        UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
        // Commenting for now but change in future
        LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
        Bookmarked: null,
        NeverAttempted: null,
        Mistakes: null,
        questionCount: 10,
      }))

      dispatch(CheckedBranch(false))
      dispatch(updateDates({
        startDate: new Date(),
        endDate: new Date(),
      }))

      dispatch(BranchesFilter({
        startDate: (moment(new Date()).format('YYYY-MM-DD')),
        endDate: (moment(new Date()).format('YYYY-MM-DD')),

      }))

      dispatch(ChapterFilter({
        startDate: (moment(new Date()).format('YYYY-MM-DD')),
        endDate: (moment(new Date()).format('YYYY-MM-DD')),
        object: []
      }))
      dispatch(SubChapterFilter({
        startDate: (moment(new Date()).format('YYYY-MM-DD')),
        endDate: (moment(new Date()).format('YYYY-MM-DD')),
        object: []
      }))
      dispatch(ChnageTabs(0))

      localStorage.removeItem("Past_Week_Date", JSON.stringify(weekDate));
      localStorage.removeItem("Past_Month_Date", JSON.stringify(monthDate));
      localStorage.removeItem("Custom_Tab");


      var element = document.getElementById("colorChange");
      element.classList.remove("create-posts");
      element.classList.add("btn-red");

      var custom = document.getElementById("customBtn");
      custom.classList.remove("btn-red");
      custom.classList.add("create-posts");

      var element = document.getElementById("thisWeek");
      element.classList.remove("btn-red");
      element.classList.add("create-posts");

      var elements = document.getElementById("pastWeek");
      elements.classList.remove("btn-red");
      elements.classList.add("create-posts");
      setHideQuiz(false)
      localStorage.setItem("Today_Date", JSON.stringify({
        startDate: (moment(new Date()).format('YYYY-MM-DD')),
        endDate: (moment(new Date()).format('YYYY-MM-DD')),
      }));


    }

    const ThisWeekQuiz = () => {
      const now = new Date();
      const lastweek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);

      dispatch(CheckedBranch(false))
      dispatch(updateDates({
        startDate: lastweek,
        endDate: now
      }))
      dispatch(quizQuestion({
        startDate: (moment(lastweek).format('YYYY-MM-DD')),
        endDate: (moment(now).format('YYYY-MM-DD')),
        branchId: [],
        chapterId: [],
        subChapterId: [],
        // Commenting for now but change in future
        // LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
        CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
        UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
        // Commenting for now but change in future
        LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
        Bookmarked: null,
        NeverAttempted: null,
        Mistakes: null,
        questionCount: 10,
      }))


      localStorage.setItem("Past_Week_Date", JSON.stringify({
        startDate: lastweek,
        endDate: now
      }));
      localStorage.removeItem("Custom_Tab", JSON.stringify(CustomTab));
      localStorage.removeItem("Today_Date", JSON.stringify(todayDate));
      localStorage.removeItem("Past_Month_Date", JSON.stringify(monthDate));

      dispatch(BranchesFilter({
        startDate: (moment(lastweek).format('YYYY-MM-DD')),
        endDate: (moment(now).format('YYYY-MM-DD')),
      }))
      dispatch(ChapterFilter({
        startDate: (moment(lastweek).format('YYYY-MM-DD')),
        endDate: (moment(now).format('YYYY-MM-DD')),
        object: []
      }))

      dispatch(SubChapterFilter({
        startDate: (moment(lastweek).format('YYYY-MM-DD')),
        endDate: (moment(now).format('YYYY-MM-DD')),
        object: []
      }))
      dispatch(ChnageTabs(0))

      var element = document.getElementById("thisWeek");
      element.classList.remove("create-posts");
      element.classList.add("btn-red");

      var custom = document.getElementById("customBtn");
      custom.classList.remove("btn-red");
      custom.classList.add("create-posts");
      // setHideQuiz(false)
      var element = document.getElementById("colorChange");
      element.classList.remove("btn-red");
      element.classList.add("create-posts");

      var custom = document.getElementById("customBtn");
      custom.classList.remove("btn-red");
      custom.classList.add("create-posts");

      var elements = document.getElementById("pastWeek");
      elements.classList.remove("btn-red");
      elements.classList.add("create-posts");
      setHideQuiz(false)

    }

    const PastWeekBtn = () => {
      const now = new Date();
      const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());

      dispatch(CheckedBranch(false))
      dispatch(quizQuestion({
        startDate: (moment(lastMonth).format('YYYY-MM-DD')),
        endDate: (moment(now).format('YYYY-MM-DD')),
        branchId: [],
        chapterId: [],
        subChapterId: [],
        // Commenting for now but change in future
        // LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
        CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
        UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
        // Commenting for now but change in future
        LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
        Bookmarked: null,
        NeverAttempted: null,
        Mistakes: null,
        questionCount: 10,
      }))
      dispatch(updateDates({
        startDate: lastMonth,
        endDate: now
      }))

      dispatch(BranchesFilter({
        startDate: (moment(lastMonth).format('YYYY-MM-DD')),
        endDate: (moment(now).format('YYYY-MM-DD')),
      }))

      dispatch(ChapterFilter({
        startDate: (moment(lastMonth).format('YYYY-MM-DD')),
        endDate: (moment(now).format('YYYY-MM-DD')),
        object: []
      }))

      dispatch(SubChapterFilter({
        startDate: (moment(lastMonth).format('YYYY-MM-DD')),
        endDate: (moment(now).format('YYYY-MM-DD')),
        object: []
      }))
      dispatch(ChnageTabs(0))
      localStorage.setItem("Past_Month_Date", JSON.stringify({
        startDate: lastMonth,
        endDate: now
      }));

      localStorage.removeItem("Custom_Tab", JSON.stringify(CustomTab));
      localStorage.removeItem("Today_Date", JSON.stringify(todayDate));
      localStorage.removeItem("Past_Week_Date", JSON.stringify(weekDate));

      var element = document.getElementById("pastWeek");
      element.classList.remove("create-posts");
      element.classList.add("btn-red");

      var custom = document.getElementById("customBtn");
      custom.classList.remove("btn-red");
      custom.classList.add("create-posts");
      // setHideQuiz(false)
      var element = document.getElementById("colorChange");
      element.classList.remove("btn-red");
      element.classList.add("create-posts");

      var custom = document.getElementById("customBtn");
      custom.classList.remove("btn-red");
      custom.classList.add("create-posts");


      var Week = document.getElementById("thisWeek");
      Week.classList.remove("btn-red");
      Week.classList.add("create-posts");
      setHideQuiz(false)
    }

    const Sorting = (e, isSorting) => {
      dispatch(quizQuestion({

        branchId: BrancheFilterData ? BrancheFilterData : [],
        chapterId: ChapterFilterData ? ChapterFilterData : [],
        subChapterId: SubChapterFilterData ? SubChapterFilterData : [],
        // Commenting for now but change in future
        // LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
        CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
        UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
        // Commenting for now but change in future
        LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
        NeverAttempted: NeverAttempted,
        Bookmarked: BookmarkedData,
        Mistakes: LocalMistakes,
        questionCount: QuestionCount == null ? 10 : QuestionCount,
        isSorting: isSorting
      }))
    }

    const IncQuestion = (e, count) => {
      dispatch(quizQuestion({

        startDate: null,
        endDate: null,
        branchId: BrancheFilterData ? BrancheFilterData : [],
        chapterId: ChapterFilterData ? ChapterFilterData : [],
        subChapterId: SubChapterFilterData ? SubChapterFilterData : [],
        // Commenting for now but change in future
        // LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
        CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
        UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
        // Commenting for now but change in future
        LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
        NeverAttempted: NeverAttempted,
        Bookmarked: BookmarkedData,
        Mistakes: LocalMistakes,
        questionCount: count,
      }))
    }

    const SearchQuiz = (e) => {
      dispatch(quizQuestion({
        branchId: BrancheFilterData ? BrancheFilterData : [],
        chapterId: ChapterFilterData ? ChapterFilterData : [],
        subChapterId: SubChapterFilterData ? SubChapterFilterData : [],
        // Commenting for now but change in future
        // LanguageId: LanguageFilterData == null ? exportselectedLanguages : LanguageFilterData,
        CountryId: CountryFilterData == null ? exportselectedCountry : CountryFilterData,
        UniversityId: UniversityFilterData == null ? exportselectedUniversity : UniversityFilterData,
        // Commenting for now but change in future
        LevelId: LevelFilterData == null ? exportselectedLevel : LevelFilterData,
        NeverAttempted: NeverAttempted,
        Bookmarked: BookmarkedData,
        Mistakes: LocalMistakes,
        questionCount: QuestionCount == null ? 10 : QuestionCount,
        search: e.target.value == "" ? null : e.target.value
      }))
    }
    const [time, setTime] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [pageLoadTime, setPageLoadTime] = useState(Date.now());
    const [isActive, setIsActive] = useState(true);

    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     if (isActive) {
    //       setTime(Date.now() - pageLoadTime);
    //     }
    //   }, 1000);
    //   return () => {
    //     clearInterval(interval);
    //   };
    // }, [isActive, pageLoadTime]);

    // useEffect(() => {

    //   const handleVisibilityChange = () => {
    //     setIsActive(!document.hidden);
    //     if (!document.hidden) {
    //       setPageLoadTime(Date.now() - time);
    //     }

    //   };
    //   document.addEventListener('visibilitychange', handleVisibilityChange);
    //   return () => {
    //     document.removeEventListener('visibilitychange', handleVisibilityChange);
    //   };
    // }, [time]);

    // const formattedTime = React.useMemo(() => {

    //   const minutes = Math.floor(time / 60000);
    //   const seconds = ((time % 60000) / 1000).toFixed(0);
    //   var ms = `${minutes}:${(seconds < 10 ? '0' : '')}${seconds}`
    //   var a = ms.split(':');
    //   var mintoSec = (+a[0]) * 60 + (+a[1]);
    //   console.log(mintoSec)
    //   return setTimeout(() => TrackSocailTabTime(mintoSec), 1000)
    // }, [time]);

    // const [getData, setGetData] = useState()
    // const dbRef = ref(getDatabase());
    // get(child(dbRef, `onlineUsers/${data.id}`)).then((snapshot) => {
    //   if (snapshot.exists()) {
    //     setGetData(snapshot.val())
    //   } else {
    //     console.log("No data available");
    //   }
    // }).catch((error) => {
    //   console.error("errrr", error);
    // });
    // function TrackSocailTabTime(formattedTime) {

    //   if (getData?.hasOwnProperty('timeSpendOnQuiz')) {
    //     var newTimeSpendOnQuiz = getData?.timeSpendOnQuiz;
    //   }
    //   else {
    //     var newTimeSpendOnQuiz = 0
    //   }
    //   try {
    //     update(ref(db, 'onlineUsers/' + data.id), {
    //       id: data.id,
    //       timeSpendOnQuiz: formattedTime + newTimeSpendOnQuiz
    //     });
    //   } catch (e) {
    //     console.error("Error adding document: ", e);
    //   }
    // }
    // setInterval(function() {
    //   // Call your function here with the required parameters
    //   TrackSocailTabTime(formattedTime);
    // }, 5000);


    return (
      <>
        <Header
          divclassName="shadow-none pos-fixed position-absolute"
          color="light"
        />
        <Row className="quiz pl-3 " style={{ minHeight: '100vh' }}>
          <QuizFilter />
          <div className="col-md-8 col-sm-12">
            <Row className='mt-2'>
              <Col md="7" sm="5">
                <div className="serch-input icon-input mb-3 d-flex justify-content-end">
                  <i className="font-sm ti-search pr-0 " style={{ color: "#E84545" }} onClick={SearchQuiz}></i>
                  <input
                    type="text"
                    className='form-control-search mt-3'
                    placeholder='Search'
                    onChange={SearchQuiz}
                  />
                </div>
              </Col>
              {/* {data.isVerified==true?  */}
              <Col md="2" sm="4">
                <Link to="/create-quiz">
                  <button className='create-quiz'> <img alt="" src={add} className="w30 mr-3" />Create Quiz</button>
                </Link>
              </Col>


              <Col md='1' sm="2" style={{ marginLeft: "11px" }}>
                <Link to="/TopUser">
                   <img alt="" src={trophy} className="m-4" width="30px" />
                </Link>
              </Col>
            </Row>
            <div className='row mb-5 '>
              <div style={{ marginTop: "-6px" }} className='d-flex justify-content-evenly col-xl-8 col-lg-8 col-md-8 col-sm-8'>
                <div className='d-none' >
                  <button id='colorChange' className={tabsBtn == 4 || todayDate == null ? "create-posts" : "btn-red"} onClick={today} >Today</button>
                  &nbsp; &nbsp;
                </div>
                <div className='d-none' >
                  <button className={tabsBtn == 4 || weekDate == null ? "create-posts" : "btn-red"} id="thisWeek" onClick={ThisWeekQuiz}>This Week</button>
                  &nbsp; &nbsp;
                </div>
                <div className='d-none'>
                  <button className={tabsBtn == 4 || monthDate == null ? "create-posts" : "btn-red"} id="pastWeek" onClick={PastWeekBtn}>Past 4 Weeks</button>
                  &nbsp; &nbsp;
                </div>
                <div >
                  <button className={tabsBtn == 4 || CustomTab != null ? "btn-red " : "create-posts"} onClick={chnageColor} id="customBtn">Custom</button>
                </div>
              
              </div>
              <div className='d-flex justify-content-evenly col-xl-3 col-lg-3 col-md-3 col-sm-3'>
              <div>
                  <UncontrolledDropdown className='mt-3'
                  style={{
                    marginRight: "42px"
                  }}
                  >
                    <DropdownToggle caret style={{ backgroundColor: "#E84545", borderColor: "white" }}><MdOutlineFormatIndentIncrease fontSize="1.2rem" title='Increase Question' /></DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={(e) => { IncQuestion(e, 1) }}>+1</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={(e) => { IncQuestion(e, 3) }} >+3</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={(e) => { IncQuestion(e, 5) }} >+5</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={(e) => { IncQuestion(e, 10) }} >+10</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={(e) => { IncQuestion(e, 20) }} >+20</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={(e) => { IncQuestion(e, 50) }} >+50</DropdownItem>
                    </DropdownMenu>

                  </UncontrolledDropdown>
                </div>

                <div>
                  <UncontrolledDropdown className='mt-3 '
                    // style={{
                    //   marginLeft: "-72px"
                    // }}
                  >
                    <DropdownToggle caret style={{ backgroundColor: "#E84545", borderColor: "white" }}><FaSortAmountUpAlt title='Increase Question' /></DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={(e) => { Sorting(e, false) }}>Top Rated First</DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem onClick={(e) => { Sorting(e, true) }} >Newest First</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
              {hideQuiz ||
                <div className=''>{quizfilterData.quiz?.length == 0 ? <h1 style={{ margin: "180px", color: "#E84545" }}>No Question For Quiz</h1> : <TodayQuiz />}  </div>
              }
            </div>

            {hideQuiz &&
              // <CustomizedQuiz questionId={id} />
              <TodayQuiz />
            }
          </div>
        </Row>
      </>
    );
  }


  export default Quiz;
