import React, { useEffect, useState, useCallback } from "react";
import { Col } from "reactstrap";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
// import Switch from "react-bootstrap-switch";
// import "react-bootstrap-switch/dist/css/bootstrap3/react-bootstrap-switch.min.css";
import Swal from "sweetalert2";
import AdminServices from "../../../../services/AdminServices";

const AllUsers = ({ adminId, GetBranchesByLevelId }) => {
  const dispatch = useDispatch();
  const [GetAllUser, setGetAllUser] = useState([]);

  // Fetching all users based on branches and user type
  const GetAllUsers = useCallback(async () => {
    try {
      const res = await AdminServices.GetAllUsersApi({
        levelId: GetBranchesByLevelId,
        userType: 0,
      });
      setGetAllUser(res.data.payload);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [GetBranchesByLevelId]);

  useEffect(() => {
    GetAllUsers();
  }, [GetAllUsers]);

  // Handle student verification toggle
  const StudentVerified = async (el, state, id) => {
    debugger
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });

      if (result.isConfirmed) {
        const response = await AdminServices.VerifyById(id);

        if (response.data.status) {
          Swal.fire("Success!", response.data.message, "success");
          // Optimally update the single user verification status without refetching
          setGetAllUser((prevUsers) =>
            prevUsers.map((user) =>
              user.id === id ? { ...user, isVerified: true } : user
            )
          );
        } else {
          Swal.fire("Problem!", response.data.message, "error");
        }
      }
    } catch (error) {
      console.error("Error verifying user:", error);
    }
  };

  // Handle student premium toggle
  const StudentPremium = async (state,el,id) => {
    debugger
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });

      if (result.isConfirmed) {
        const response = await AdminServices.MakeUserPremiumyById(
          id,
          state,
          adminId
        );

        if (response.data.status) {
          Swal.fire("Success!", response.data.message, "success");
          // Optimally update the single user premium status without refetching
          setGetAllUser((prevUsers) =>
            prevUsers.map((user) =>
              user.id === id ? { ...user, isPremium: state } : user
            )
          );
        } else {
          Swal.fire("Problem!", response.data.message, "error");
        }
      }
    } catch (error) {
      console.error("Error updating premium status:", error);
    }
  };

  return (
    <Col md="12">
      <ReactTable
        style={{ fontSize: "12px", textAlign: "center" }}
        columns={[
          {
            Header: "User",
            accessor: "user",
            Cell: (row) => (
              <div>
                {`${GetAllUser[row.index].firstName} ${GetAllUser[row.index].lastName}`}
              </div>
            ),
          },
          {
            Header: "Email",
            accessor: "email",
          },
          {
            Header: "User Type",
            accessor: "user",
            Cell: (row) => (
              <div>
                {GetAllUser[row.index].userType === 1 ? "Doctor" : "Student"}
              </div>
            ),
          },
          {
            Header: "Verified",
            accessor: "Verified",
            Cell: (row) => (
              <div className="d-flex justify-content-center">
                {GetAllUser[row.index].isVerified ? (
                  "User Verified"
                ) : (
                  <Switch
                    // bsSize="small"
                    // offColor="danger"
                    // onColor="success"
                    // onText=""
                    // offText=""
                    onChange={(el, state) =>
                      StudentVerified(el, state, GetAllUser[row.index].id)
                    }
                    checked={GetAllUser[row.index].isVerified}
                  />
                )}
              </div>
            ),
          },
          {
            Header: "Premium",
            accessor: "Premium",
            Cell: (row) => (
              <div className="d-flex justify-content-center">
                <Switch
                  // bsSize="small"
                  // offColor='#de1a24'
                  // onColor='	#056517'
                  // onText=""
                  // offText=""
                  onChange={(el, state) =>
                    StudentPremium(el, state, GetAllUser[row.index].id)
                  }
                  checked={GetAllUser[row.index].isPremium}
                />
              </div>
            ),
          },
        ]}
        defaultPageSize={5}
        className="-striped -highlight"
        data={GetAllUser}
      />
    </Col>
  );
};

export default AllUsers;
